<template>
  <div class="grid grid-cols-1 gap-6 lg:gap-x-12 lg:gap-y-11 sm:grid-cols-2 lg:px-16 lg:items-center">
    <div class="col-span-full flex flex-col items-center text-center gap-4">
      <h2 class="text-4xl font-medium tracking-tight">Why Keep a Dream Diary?</h2>
      <p class="text-lg max-w-lg">A dream diary is more than just a journal for dreams - it’s a tool for
        self-discovery. Here
        are some of the
        benefits.</p>
    </div>
    <div class="p-8 bg-white dark:bg-stone-800 rounded-lg flex flex-col justify-center self-stretch">
      <h2 class="text-4xl font-medium tracking-tight">Enhanced Dream Recall</h2>
      <p class="text-lg max-w-lg">Regularly writing down your dreams helps you remember more details and increases
        dream awareness.</p>
    </div>
    <div class="p-8 bg-white dark:bg-stone-800 rounded-lg flex flex-col justify-center self-stretch">
      <h2 class="text-4xl font-medium tracking-tight">Lucid Dreaming</h2>
      <p class="text-lg max-w-lg">A dream diary can aid in recognizing patterns in your dreams, making it easier to
        identify when you are dreaming and eventually take control of the dream.</p>
    </div>
    <div class="p-8 bg-white dark:bg-stone-800 rounded-lg flex flex-col justify-center self-stretch">
      <h2 class="text-4xl font-medium tracking-tight">Self-Reflection</h2>
      <p class="text-lg max-w-lg">Dreams often reveal hidden thoughts and emotions. Recording them can lead to a
        deeper understanding of your subconscious.</p>
    </div>
    <div class="p-8 bg-white dark:bg-stone-800 rounded-lg flex flex-col justify-center self-stretch">
      <h2 class="text-4xl font-medium tracking-tight">Improved Sleep Quality</h2>
      <p class="text-lg max-w-lg">Being mindful of your dream habits can help track your sleep patterns and promote
        better rest.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>